import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState, Status } from 'store/types';

import { WebsiteSportService } from 'api/websiteSport';

export enum SportType {
  live = 'live',
  home = 'home',
  premtach = 'prematch',
}

export type WebsiteSport = {
  id: number;
  meta: number | string;
  types: SportType[];
  url?: string;
  cid?: string;
};

type WebsiteSportStateType = {
  data: WebsiteSport[];
  status: Status;
  softSwissPostFix: string;
  addOneXBetPostFix: string;
  hasAtlasSport: boolean;
  hasSoftSwissSport: boolean;
  hasBetBySport: boolean;
  hasDigitalSportTechSport: boolean;
  hasNineWicketsSport: boolean;
  hasOneXBetSport: boolean;
  hasTurboStarsSport: boolean;
  hasLatroBetSport: boolean;
};

type WebsiteSportActionsType = {
  read: () => void;
  addSoftSwissPostFix: (postFix: string) => void;
};

const initialState: WebsiteSportStateType = {
  data: [],
  hasAtlasSport: false,
  softSwissPostFix: '',
  addOneXBetPostFix: '',
  hasSoftSwissSport: false,
  hasBetBySport: false,
  hasDigitalSportTechSport: false,
  hasNineWicketsSport: false,
  hasOneXBetSport: false,
  hasTurboStarsSport: false,
  hasLatroBetSport: false,
  status: 'idle',
};

export enum SportProviders {
  ATLAS_ID = 1,
  SOFT_SWISS_ID = 2,
  BET_BY_ID = 3,
  DIGITAL_SPORT_TECH = 4,
  NINE_WICKETS = 5,
  ONE_X_BET = 6,
  TURBO_STARS = 7,
  LATRO_BET = 8,
}

const readWebsiteSportApplyThunk = createAsyncThunk('website-sport/read', async () =>
  WebsiteSportService.getWebsiteSports()
);

const websiteSportSlice = createSlice({
  initialState,
  name: 'sport',
  reducers: {
    addSoftSwissPostFix: (state: WebsiteSportStateType, action: PayloadAction<string>): void => {
      state.softSwissPostFix = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(readWebsiteSportApplyThunk.pending.type, (state: WebsiteSportStateType): void => {
        state.status = 'loading';
      })
      .addCase(
        readWebsiteSportApplyThunk.fulfilled.type,
        (state: WebsiteSportStateType, action: PayloadAction<WebsiteSport[]>): void => {
          state.status = 'success';
          state.data = action.payload || initialState.data;
        }
      )
      .addCase(readWebsiteSportApplyThunk.rejected.type, (state: WebsiteSportStateType): void => {
        state.status = 'failed';
        state.data = initialState.data;
      }),
});

const useWebsiteSports = (): [WebsiteSportStateType, WebsiteSportActionsType] => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: RootState) => state.websiteSport);

  const actions = {
    read: () => {
      dispatch(readWebsiteSportApplyThunk());
    },
    addSoftSwissPostFix: (postFix: string) => {
      dispatch(websiteSportSlice.actions.addSoftSwissPostFix(postFix));
    },
  };

  return [
    {
      ...state,
      hasAtlasSport: state.data.some((sport) => sport.id === SportProviders.ATLAS_ID),
      hasBetBySport: state.data.some((sport) => sport.id === SportProviders.BET_BY_ID),
      hasSoftSwissSport: state.data.some((sport) => sport.id === SportProviders.SOFT_SWISS_ID),
      hasDigitalSportTechSport: state.data.some((sport) => sport.id === SportProviders.DIGITAL_SPORT_TECH),
      hasNineWicketsSport: state.data.some((sport) => sport.id === SportProviders.NINE_WICKETS),
      hasOneXBetSport: state.data.some((sport) => sport.id === SportProviders.ONE_X_BET),
      hasTurboStarsSport: state.data.some((sport) => sport.id === SportProviders.TURBO_STARS),
      hasLatroBetSport: state.data.some((sport) => sport.id === SportProviders.LATRO_BET),
    },
    actions,
  ];
};

export { websiteSportSlice };
export default useWebsiteSports;
