import { PaymentMethodsService } from 'api/paymentMethods';
import useMount from 'hooks/useMount';
import { useMemo, useState } from 'react';
import { AMOUNT_FIELD_NAME, DEPOSIT_CONFIG } from './constants';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useTranslates from 'utils/translate';
import FormInput from 'components/common/Form/Input';
import useToaster from 'hooks/useToaster';

const Deposit = ({ watch, control, paymentMethod, changeAmountFieldVisibility, changeActionButtonVisibility }) => {
  const amount = watch(AMOUNT_FIELD_NAME);
  const { translate } = useTranslates();
  const notify = useToaster();
  const [loading, setLoading] = useState(false);
  const configuration = DEPOSIT_CONFIG[paymentMethod.method.name];
  const originalFieldNames = useMemo(() => Object.keys(configuration), [configuration]);
  const fieldNames = useMemo(
    () => originalFieldNames.filter((fieldName) => fieldName !== AMOUNT_FIELD_NAME),
    [configuration]
  );

  useMount(() => {
    changeAmountFieldVisibility(originalFieldNames.includes(AMOUNT_FIELD_NAME));
    changeActionButtonVisibility(false);
  });

  const onClick = async () => {
    try {
      setLoading(true);
      const result = await PaymentMethodsService.getPaymentMethodExtraInfo<[{ key: string; value: string }]>({
        amount,
        sourceId: paymentMethod.method.id,
        type: 'deposit',
        ...(!!fieldNames.length && {
          infoProperties: fieldNames.reduce((acc, fieldName) => {
            acc[fieldName] = watch(fieldName);
            return acc;
          }, {}),
        }),
      });
      if (result[0]) {
        window.location.href = result[0].value;
      } else {
        notify({
          message: translate('payment_invalid_credentials'),
          type: 'error',
        });
        setLoading(false);
      }
    } catch (error) {
      notify({
        message: translate((error as Error).toString()),
        type: 'error',
      });
      setLoading(false);
    }
  };

  const areAllFieldsFilled = originalFieldNames.every((fieldName) => Boolean(watch(fieldName)));

  return (
    <>
      {fieldNames.map((fieldName) => (
        <Grid item xs={24} key={fieldName}>
          <FormInput label={translate(fieldName)} name={fieldName} required control={control} />
        </Grid>
      ))}
      <Grid item xs={12}>
        <LoadingButton
          disabled={!areAllFieldsFilled}
          variant="contained"
          color="success"
          loading={loading}
          onClick={onClick}
          sx={{ mt: 3 }}
        >
          {translate('submit_deposit')}
        </LoadingButton>
      </Grid>
    </>
  );
};

export default Deposit;
