import { Box, FormControlLabel, Checkbox, Typography, Button } from '@mui/material';
import TelegramLogin from 'components/common/TelegramLogin';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import useDialog from 'store/dialog';
import useDomainSettings from 'store/domains';
import useSettings, { IWebsiteConfigs } from 'store/settings';
import { useTranslates } from 'utils/translate';

const SignupActions = ({ isLoading, onTelegramSuccess }: { isLoading: boolean; onTelegramSuccess: (user) => void }) => {
  const { translate } = useTranslates();
  const [
    {
      data: { meta = {} as IWebsiteConfigs },
    },
  ] = useSettings();
  const [
    {
      data: { telegramBotName },
    },
  ] = useDomainSettings();
  const [, dialogActions] = useDialog();
  const [isSignedAgreement, setIsSignedAgreement] = useState(false);

  return (
    <>
      {meta.showAgreementSignature && (
        <Box textAlign="center">
          <FormControlLabel
            control={<Checkbox />}
            value={isSignedAgreement}
            onChange={() => setIsSignedAgreement((prev) => !prev)}
            label={
              <Typography
                fontSize={12}
                fontWeight={600}
                sx={{ '& > a': { color: 'primary.main', textDecoration: 'underline' } }}
              >
                {translate('i_agree_with')}{' '}
                <Link to="/terms-and-conditions" target="_blank">
                  {translate('terms_conditions')}
                </Link>
              </Typography>
            }
          />
        </Box>
      )}
      <Button
        disabled={isLoading || (!isSignedAgreement && meta.showAgreementSignature)}
        variant="contained"
        type="submit"
      >
        {translate('sign_up')}
      </Button>
      {!!telegramBotName && <TelegramLogin botName={telegramBotName} onSuccess={onTelegramSuccess} />}
      <Box>
        <Typography fontSize={12} textAlign="center" mt={1}>
          {translate('already_have_an_account')}
        </Typography>
        <Button disabled={isLoading} size="small" fullWidth onClick={() => dialogActions.set('signIn')}>
          {translate('sign_in')}
        </Button>
      </Box>
    </>
  );
};

export default SignupActions;
