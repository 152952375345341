import _Request from '../Request';

import { ICategory, ICountry, IGameTag, IGlobalSettings, ILanguage } from './types';

class SettingsService extends _Request {
  constructor() {
    super('/');
  }

  getGamesTags(params: { category: number }) {
    return this.send<IGameTag[]>({ params, method: 'get' }, '/tags').then((response) => response);
  }

  getAllCountries() {
    return this.send<ICountry[]>({ method: 'get' }, '/countries/all').then((response) => response);
  }

  getCountries() {
    return this.send<ICountry[]>({ method: 'get' }, '/countries').then((response) => response);
  }

  getCategories() {
    return this.send<ICategory[]>({ method: 'get' }, '/categories').then((response) => response);
  }

  getSettings() {
    return this.send<IGlobalSettings>({ method: 'get' }, '/settings').then((response) => response);
  }

  getLanguages() {
    return this.send<ILanguage[]>({ method: 'get' }, '/common/website/language');
  }

  forgotPassword(data) {
    return this.send({ data, method: 'post' }, '/forgotPassword').then((response) => response);
  }

  changePassword(data, playerId) {
    return this.send({ data, method: 'put' }, `/players/${playerId}/password`).then((response) => response);
  }

  resetPassword(data) {
    return this.send({ data, method: 'post' }, '/resetPassword').then((response) => response);
  }

  emailConfirmation(data) {
    return this.send<{ response: { status: number } }>({ data }, '/verify/email').then((response) => response);
  }

  websiteContent() {
    return this.send({ method: 'get' }, '/website/content').then((response) => response);
  }

  getInitialSettings() {
    return Promise.all([this.getCategories()]).then(([categories]) => ({ categories }));
  }
}

export default new SettingsService();
