import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import useSelectedTheme from 'store/theme';

const Android12Switch = styled(Switch)(({ theme }) => ({
  width: 65,
  height: 30,
  padding: 0,
  '& .MuiSwitch-track': {
    borderRadius: 20,
    background: theme.palette.secondary.light,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 18,
      height: 18,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      filter: 'invert(100%)',
    },
    '&:before': {
      backgroundImage: `url(https://cdn-icons-png.flaticon.com/512/6714/6714978.png)`,
      left: 4,
    },
    '&:after': {
      backgroundImage: `url(https://static.thenounproject.com/png/4808961-200.png)`,
      right: 4,
    },
  },
  '& .MuiButtonBase-root': {
    padding: 4,
    '&.Mui-checked': {
      transform: 'translateX(35px)',
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 22,
    height: 22,
    margin: 0,
    background: theme.palette.primary.main,
  },
}));

const ThemeSwitcher = () => {
  const [{ isDark }, themeActions] = useSelectedTheme();

  return (
    <>
      <Android12Switch checked={!isDark} onClick={() => themeActions.set(isDark ? 'light' : 'dark')} data-testid="theme-switcher" />
    </>
  );
};

export default ThemeSwitcher;
