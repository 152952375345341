import { FC, Fragment } from 'react';
import { SportProviders } from 'store/websiteSports';

import AssignmentIcon from '@mui/icons-material/Assignment';
import GradeIcon from '@mui/icons-material/Grade';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import SportIcon from 'components/common/icons/Sport';
import SportLiveIcon from 'components/common/icons/SportLive';

import useCurrentUser from 'store/currentUser';
import useSettings, { IWebsiteConfigs } from 'store/settings';
import useWebsiteSports from 'store/websiteSports';

import useTranslates from 'utils/translate';

import MenuItem from '../MenuItem';
import { hasLiveType, hasPrematchType } from 'utils/predicates';
import { useSportQueries } from 'hooks/useSportLinks';

interface ISportLinksProps {
  onItemClick: () => void;
}

const SportLinks: FC<ISportLinksProps> = ({ onItemClick }) => {
  const { getLiveQuery, getSportQuery } = useSportQueries();
  const { translate } = useTranslates();

  const [currentUser] = useCurrentUser();
  const [websiteSports] = useWebsiteSports();
  const [
    {
      data: { meta = {} as IWebsiteConfigs },
    },
  ] = useSettings();

  return (
    <Fragment>
      {websiteSports.data.map((websiteSport, index) => {
        const path = !meta?.isHomepageActive && !index ? '/' : `/sport/${websiteSport.id}`;
        const isSoftSwiss = websiteSport.id === SportProviders.SOFT_SWISS_ID;
        return (
          <Fragment key={index}>
            {hasLiveType(websiteSport) && (
              <MenuItem
                to={`${path}${getLiveQuery(websiteSport)}`}
                icon={<SportLiveIcon color="primary" />}
                onClick={onItemClick}
              >
                {translate(`live_${websiteSport.id}`)}
              </MenuItem>
            )}
            {hasPrematchType(websiteSport) && (
              <MenuItem
                to={`${path}${getSportQuery(websiteSport)}`}
                icon={<SportIcon color="primary" />}
                onClick={onItemClick}
              >
                {translate(isSoftSwiss ? 'prematch' : `sport_${websiteSport.id}`)}
              </MenuItem>
            )}
            {isSoftSwiss && (
              <>
                <MenuItem
                  to={`${path}?tab=cybersport`}
                  icon={<SportsEsportsIcon color="primary" />}
                  onClick={onItemClick}
                >
                  {translate('esports')}
                </MenuItem>
                {!!Object.keys(currentUser.data).length && (
                  <MenuItem to={`${path}?tab=favorites`} icon={<GradeIcon color="primary" />} onClick={onItemClick}>
                    {translate('favorites')}
                  </MenuItem>
                )}
                <MenuItem to={`${path}?tab=results`} icon={<AssignmentIcon color="primary" />} onClick={onItemClick}>
                  {translate('results')}
                </MenuItem>
              </>
            )}
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default SportLinks;
