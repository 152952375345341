import { DEFAULT_META_KEY } from 'config';
import { useEffect, useState } from 'react';
import useWebsiteMetas from 'store/metas';
import useSettings from 'store/settings';

const useDynamicManifest = () => {
  const [settings] = useSettings();
  const [state] = useWebsiteMetas();
  const [generated, setGenerated] = useState(false);

  const defaultSelected = state?.data?.find((item) => item.page === DEFAULT_META_KEY);

  useEffect(() => {
    if (settings.data && defaultSelected && !generated) {
      const partnerManifest = {
        start_url: window.location.origin,
        display: 'standalone',
        short_name: defaultSelected?.metaTitle,
        name: defaultSelected?.metaTitle,
        icons: [
          {
            src: settings.data.favicon,
            sizes: '64x64 32x32 24x24 16x16',
            type: 'image/x-icon',
          },
          {
            src: settings.data.favicon,
            type: 'image/png',
            sizes: '192x192',
          },
          {
            src: settings.data.favicon,
            type: 'image/png',
            sizes: '512x512',
          },
        ],
        theme_color: settings.data?.mainColor,
        background_color: settings.data?.mainColor,
      };
      const blob = new Blob([JSON.stringify(partnerManifest)], { type: 'application/json' });
      const url = URL.createObjectURL(blob);

      let manifestLink = document.querySelector('link[rel="manifest"]') as HTMLLinkElement;
      if (!manifestLink) {
        manifestLink = document.createElement('link');
        manifestLink.rel = 'manifest';
        document.head.appendChild(manifestLink);
      }
      manifestLink.href = url;
      setGenerated(true);
    }
  }, [settings.data, defaultSelected, generated]);

  return { generated };
};

export default useDynamicManifest;
