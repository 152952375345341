import { useCallback } from 'react';
import useSelectedLanguage from 'store/language';
import { SportProviders, WebsiteSport } from 'store/websiteSports';

const useSportQueries = () => {
  const [{ selectedLanguage }] = useSelectedLanguage();

  const getLiveQuery = useCallback(
    (websiteSport?: WebsiteSport) => {
      if (!websiteSport) return '';

      if (websiteSport.id === SportProviders.BET_BY_ID) return '?bt-path=/live';

      if (websiteSport.id === SportProviders.ONE_X_BET) return `?tab=/${selectedLanguage?.iso_1}/live`;

      return '';
    },
    [selectedLanguage]
  );

  const getSportQuery = useCallback(
    (websiteSport?: WebsiteSport) => {
      if (!websiteSport) return '';

      if (websiteSport.id === SportProviders.BET_BY_ID) return '';

      if (websiteSport.id === SportProviders.ONE_X_BET) return `?tab=/${selectedLanguage?.iso_1}/line`;

      if (websiteSport.id === SportProviders.SOFT_SWISS_ID) return '?tab=schedule';

      return '?tab=sport';
    },
    [selectedLanguage]
  );

  return {
    getLiveQuery,
    getSportQuery,
  };
};

export { useSportQueries };
