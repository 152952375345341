import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, SxProps, Typography } from '@mui/material';
import useToaster from 'hooks/useToaster';
import useTranslates from 'utils/translate';

const Copy = ({ text, sx }: { text: string; sx?: SxProps }) => {
  const notify = useToaster();
  const { translate } = useTranslates();
  const copyToClipboard = () => {
    navigator.clipboard.writeText(text);
    notify({
      message: translate('copied_to_clipboard'),
    });
  };

  return (
    <Box display="flex" gap="8px" sx={sx}>
      <Typography
        fontStyle="italic"
        fontWeight={600}
        fontSize="14px"
        alignContent="end"
        style={{ wordBreak: 'break-all' }}
      >
        {text}
      </Typography>
      <ContentCopyIcon style={{ cursor: 'pointer' }} onClick={copyToClipboard} />
    </Box>
  );
};

export default Copy;
