import { Suspense, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import DialogsManagement from 'components/sections/Dialogs';
import Footer from 'components/sections/Footer';
import Navigation from 'components/sections/Navigation';

import useBanners from 'store/banners';
import useCategories from 'store/categories';
import useCountries from 'store/countries';
import useCurrentUser from 'store/currentUser';
import useFavoritesAndRecents from 'store/favorites';
import useGlobalSettings from 'store/globalSettings';
import useSelectedLanguage from 'store/language';
import useLanguagesList from 'store/languagesList';
import useSettings, { IWebsiteConfigs } from 'store/settings';
import useWebsiteSports from 'store/websiteSports';

import useScreen from 'hooks/useScreen';
import useSearchParamsTriggers from 'hooks/useSearchParamsTriggers';
import useSockets from 'hooks/useSockets';

import useDomainSettings from 'store/domains';
import useWebsiteContent from 'store/websiteContent';
import { USER_LOGOUT } from 'utils/logoutListener';
import AppLoading from './AppLoading';
import Metatags from './Metatags';
import SwitchRouter from './SwitchRouter';
import appPages from './routes';
import useMount from 'hooks/useMount';
import useDynamicManifest from 'hooks/useDynamicManifest';

const PagesLayout = (): JSX.Element => {
  const [isAppLoading, setIsAppLoading] = useState(true);

  const deferredPrompt = useRef(null);
  const { isMobile } = useScreen();
  const socketActions = useSockets();
  const { pathname } = useLocation();
  const [, bannersActions] = useBanners();
  const [
    {
      data: { meta = {} as IWebsiteConfigs },
    },
    settingsActions,
  ] = useSettings();

  const [, countriesActions] = useCountries();
  const [, websiteContentActions] = useWebsiteContent();
  const [, favoritesActions] = useFavoritesAndRecents();
  const [, globalSettingsActions] = useGlobalSettings();
  const [, categoriesActions] = useCategories();
  const [, websiteSportsActions] = useWebsiteSports();
  const [, languagesActions] = useLanguagesList();
  const [currentUser, currentUserActions] = useCurrentUser();
  const [selectedLanguage] = useSelectedLanguage();
  const [_, domainActions] = useDomainSettings();

  const { generated } = useDynamicManifest();

  useSearchParamsTriggers();

  useMount(() => {
    (async () => {
      await languagesActions.read();
      countriesActions.read();
      currentUserActions.read();
      globalSettingsActions.read();
      websiteSportsActions.read();
      domainActions.read();
      websiteContentActions.read();
      settingsActions.read().finally(() => {
        setIsAppLoading(false);
      });
    })();
  });

  useEffect(() => {
    if (selectedLanguage?.id) {
      bannersActions.read();
      categoriesActions.read();
    }
  }, [selectedLanguage?.id]);

  useEffect(() => {
    if (currentUser?.data?.id) {
      socketActions.connect();
      favoritesActions.read();
    }
  }, [currentUser?.data?.id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useMount(() => {
    const beforeInstallPromptHandler = (e) => {
      console.log('>>>>>>>>>>>>>>>>>>>>>>>');
      e.preventDefault();
      deferredPrompt.current = e;

      if (meta.showPWAInstall && generated) {
        deferredPrompt.current.prompt();
      }
    };
    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    };
  });

  useEffect(() => {
    if (meta.showPWAInstall && generated) {
      deferredPrompt.current?.prompt();
    }
  }, [meta.showPWAInstall]);

  useMount(() => {
    const logoutListener = () => {
      currentUserActions.clear();
    };
    window.addEventListener(USER_LOGOUT, logoutListener);
    return () => window.removeEventListener(USER_LOGOUT, logoutListener);
  });

  const isSportSelected = isMobile && pathname === '/sport';
  const showFooter = !pathname.includes('/game/') && !isSportSelected;

  return (
    <>
      {isAppLoading && <AppLoading />}
      <Box>
        <Metatags />
        <Navigation />
        <DialogsManagement />
        <Suspense fallback={<AppLoading />}>
          <SwitchRouter routes={appPages} />
        </Suspense>
      </Box>
      {showFooter && <Footer />}
    </>
  );
};

export default PagesLayout;
