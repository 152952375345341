import { FC, useRef, useCallback } from 'react';

import { Dialog, DialogTitle, DialogContent, Typography, Button, Box, Stack } from '@mui/material';
import { toPng } from 'html-to-image';

import useTranslates from 'utils/translate';
import Copy from 'components/common/Copy';
import useDialog from 'store/dialog';
import AuthService from 'api/auth/AuthService';
import useGlobalSettings from 'store/globalSettings';
import { TwoFactorAuthenticationState } from 'api/settings';
import useSockets from 'hooks/useSockets';
import useCurrentUser from 'store/currentUser';

interface CredentialsDialogProps {
  open: boolean;
  onClose: () => void;
}

const CredentialsDialog: FC<CredentialsDialogProps> = ({ open, onClose }) => {
  const socket = useSockets();
  const [globalSettings] = useGlobalSettings();
  const [{ params }, dialogActions] = useDialog();
  const [, currentUserActions] = useCurrentUser();
  const infoBoxRef = useRef();

  const { translate } = useTranslates();

  const handleClose = async () => {
    const response = await AuthService.signIn({
      email: params.username as string,
      password: params.password as string,
    });
    onClose();
    if (globalSettings.data.twoFactorState === TwoFactorAuthenticationState.mandatory) {
      return dialogActions.set('2fa');
    }
    if (response.id) {
      currentUserActions.set(response);
      socket.connect();
    }
  };

  const handleSaveImage = useCallback(() => {
    if (!infoBoxRef?.current) {
      return;
    }

    toPng(infoBoxRef.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'credentials.png';
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.error(err);
      });
  }, [infoBoxRef]);

  const handleSaveText = useCallback(() => {
    const link = document.createElement('a');
    const file = new Blob(
      [`${translate('username')}: ${params.username}\n`, `${translate('password')}: ${params.password}`],
      {
        type: 'text/plain',
      }
    );
    link.href = URL.createObjectURL(file);
    link.download = 'credentials.txt';
    link.click();
  }, []);

  return (
    <Dialog keepMounted open={open} disableEscapeKeyDown>
      <DialogTitle>
        <Typography fontSize={18} fontWeight={600}>
          {translate('credentials_dialog_title')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography textAlign="center" marginBottom="24px">
          {translate('credentials_dialog_description')}
        </Typography>
        <Box ref={infoBoxRef}>
          <Box textAlign="center" display="flex" justifyContent="center" gap="16px">
            {translate('username')}
            <Copy text={params.username as string} />
          </Box>
          <Box textAlign="center" display="flex" justifyContent="center" gap="16px">
            {translate('password')}
            <Copy text={params.password as string} />
          </Box>
        </Box>
        <Stack width="100%" direction="row" justifyContent="center" gap={2}>
          <Button variant="contained" color="success" sx={{ flexGrow: 1 }} onClick={handleSaveImage}>
            {translate('reg_save_image')}
          </Button>
          <Button variant="contained" color="success" sx={{ flexGrow: 1 }} onClick={handleSaveText}>
            {translate('reg_save_txt')}
          </Button>
        </Stack>
        <Button variant="contained" onClick={handleClose} color="primary" style={{ width: '100%', marginTop: '16px' }}>
          {translate('credentials_dialog_action')}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default CredentialsDialog;
