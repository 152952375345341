import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { languageStorage, documentDirStorage } from 'store/storage';

import { DirectionEnum, RTL_DIRECTION_LANGUAGES } from 'config';
import useLanguagesList from 'store/languagesList';
import { ILanguage } from 'api/settings';

export type SelectedLanguageStateType = {
  id: number;
  dir: DirectionEnum;
  selectedLanguage?: ILanguage;
  isRtl?: boolean;
};

type SelectedLanguageActionsType = {
  set: (land_id: number) => void;
};

const initialState: SelectedLanguageStateType = {
  id: languageStorage.get(),
  dir: documentDirStorage.get(),
};

const selectedLanguageSlice = createSlice({
  initialState,
  name: 'selectedLanguage',
  reducers: {
    set: (state, action) => {
      const id = action.payload;

      if (state.id !== id) {
        const dir = RTL_DIRECTION_LANGUAGES.includes(id) ? DirectionEnum.rtl : DirectionEnum.ltr;

        document.querySelector('html').setAttribute('dir', dir);
        documentDirStorage.set(dir);

        languageStorage.set(id);

        state = {
          id,
          dir,
        };
      }

      return state;
    },
  },
});

const useSelectedLanguage = (): [SelectedLanguageStateType, SelectedLanguageActionsType] => {
  const dispatch = useAppDispatch();
  const [languagesList] = useLanguagesList();
  const state = useAppSelector((state: RootState) => state.selectedLanguage);

  const actions = {
    set: (landId: number) => {
      dispatch(selectedLanguageSlice.actions.set(landId));
    },
  };

  const selectedLanguage = languagesList.data.find((lang) => lang.langId === state.id);
  const defaultLanguage = languagesList.data.find((lang) => lang.isDefault);

  if (!selectedLanguage && defaultLanguage) {
    actions.set(defaultLanguage?.langId);
  }

  return [
    {
      ...state,
      isRtl: state.dir === DirectionEnum.rtl,
      selectedLanguage: selectedLanguage || defaultLanguage,
    },
    actions,
  ];
};

export { selectedLanguageSlice };
export default useSelectedLanguage;
