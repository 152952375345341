import { accessStorage, clearStorage, userStorage } from 'store/storage';

import _Request from '../Request';

import { IOneClickSignUpProps, ISignInProps, ISignInResponse, ISignUpProps, IUsernameParams } from './types';
import { IPlayer } from '../../types/auth';

class AuthService extends _Request {
  constructor() {
    super('');
  }

  signIn(data: ISignInProps) {
    return this.send<ISignInResponse>({ data }, '/login').then(({ accessToken, player }) => {
      userStorage.set(player);
      accessStorage.set(accessToken);

      return player;
    });
  }

  enableTwoFactorAuthentication(data: { code: string; password: string; secret: string; enabled: boolean }) {
    return this.send<ISignInResponse>({ data, method: 'put' }, '/2fa/enable').then(({ accessToken, player }) => {
      accessStorage.set(accessToken);
      return player;
    });
  }

  verifyTwoFactorAuthentication(data: { code: string }) {
    return this.send<ISignInResponse>({ data }, '/2fa/verify').then(({ accessToken, player }) => {
      accessStorage.set(accessToken);
      return player;
    });
  }

  telegramAuth<T>(data: T) {
    return this.send<ISignInResponse>({ data }, '/telegram/auth').then(({ accessToken, player }) => {
      userStorage.set(player);
      accessStorage.set(accessToken);

      return player;
    });
  }

  signUp({ query, ...data }: ISignUpProps) {
    return this.send({ data }, `/players${query ? `?${query}` : ''}`);
  }

  oneClickSignUp({ query,...data }: IOneClickSignUpProps): Promise<IUsernameParams> {
    return this.send({ data }, `/players/one-click${query ? `?${query}` : ''}`);
  }

  signOut() {
    const user = userStorage.get();
    const data = { id: user.id };
    return Promise.resolve().then(() => {
      this.send<IPlayer>({ data }, `/logout`);
      clearStorage();
    });
  }
}

export default new AuthService();
