import { createSlice } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';

type DialogType =
  | 'signIn'
  | 'signUp'
  | 'accountSettings'
  | 'changePassword'
  | 'messages'
  | 'forgotPassword'
  | 'resetPassword'
  | 'emailConfirmation'
  | 'withdraw'
  | 'deposit'
  | 'verification'
  | 'balanceExceptions'
  | 'verifyEmail'
  | '2fa'
  | 'credentials'
  | '';

type DialogStateType = {
  type: DialogType;
  params: Record<string, unknown>;
};

type DialogActionsType = {
  set: (type: DialogType, params?: DialogStateType['params']) => void;
};

const initialState: DialogStateType = {
  type: '',
  params: {},
};

const dialogSlice = createSlice({
  initialState,
  name: 'dialog',
  reducers: {
    set: (state, action) => {
      state = action.payload;
      state.params = state.params || {};

      return state;
    },
  },
});

const useDialog = (): [DialogStateType, DialogActionsType] => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: RootState) => state.dialog);

  const actions = {
    set: (type: DialogType, params?: DialogStateType['params']) => {
      dispatch(dialogSlice.actions.set({ type, params }));
    },
  };

  return [state, actions];
};

export { dialogSlice };
export default useDialog;
