import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import pick from 'lodash/pick';

import { Box, Dialog, DialogTitle, DialogContent, IconButton, Typography, Stack, Button } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

import CloseIcon from 'components/common/svg/close';
import Form from 'components/common/Form/Form';
import FormInput from 'components/common/Form/Input';
import FormSelect from 'components/common/Form/Select';
import FormPhoneInput from 'components/common/Form/PhoneInput';

import AccountIcon from 'components/common/icons/Account';

import VerifiedIcon from 'components/common/svg/Verified';
import AwaitingIcon from 'components/common/svg/AwaitingIcon';
import FormDatePicker from 'components/common/Form/DatePicker';

import useCountries from 'store/countries';
import useCurrentUser from 'store/currentUser';
import useSelectedLanguage from 'store/language';

import { ProfileService } from 'api/profile';

import useToaster from 'hooks/useToaster';
import useTranslates from 'utils/translate';

import { updateUserResolver } from 'config/resolvers';

import sx from './styles';
import { VerificationStatus } from 'types/auth';
import VerifyEmail from '../VerifyEmail';

interface AccountSettingsPopupProps {
  open: boolean;
  onClose: () => void;
}

const AccountSettingsPopup: FC<AccountSettingsPopupProps> = ({ open, onClose }) => {
  const notify = useToaster();
  const { translate } = useTranslates();
  const [{ isRtl }] = useSelectedLanguage();
  const [emailOpen, setEmailOpen] = useState(false);

  const [countries] = useCountries();
  const [currentUser, currentUserActions] = useCurrentUser();

  const { control, reset, handleSubmit } = useForm({
    resolver: updateUserResolver,
  });

  useEffect(() => {
    reset({
      ...currentUser?.data,
      firstName: currentUser?.data?.firstName || '',
      lastName: currentUser?.data?.lastName || '',
      country: currentUser?.data?.country || '',
      city: currentUser?.data?.city || '',
      addressOne: currentUser?.data?.addressOne || '',
      email: currentUser?.data?.email || '',
      phoneNumber: currentUser?.data?.phoneNumber || '',
    });
  }, [currentUser, open]);

  const onUpdateUserData = (data) => {
    ProfileService.update(
      pick(data, ['firstName', 'lastName', 'birthDate', 'country', 'city', 'addressOne', 'email', 'phoneNumber'])
    ).then((res) => {
      if (res?.id) {
        onClose();
        currentUserActions.set(res);
        notify({
          message: translate('profile_successfully_updated'),
        });
      }
    });
  };

  const handleVerify = async () => {
    try {
      await ProfileService.resendEmail();
      setEmailOpen(true);
    } catch (error) {
      notify({
        type: 'error',
        message: (error as Error)?.message || translate('something_went_wrong'),
      });
    }
  };

  const handleEmailClose = () => {
    setEmailOpen(false);
  };

  const verification_status = {
    [VerificationStatus.VERIFIED]: <VerifiedIcon />,
    [VerificationStatus.AWAITING_VERIFICATION]: <AwaitingIcon />,
  }[currentUser?.data?.verificationStatus];

  return (
    <Dialog keepMounted open={open} onClose={onClose}>
      <DialogTitle>
        <Typography fontSize={18} fontWeight={600}>
          {translate('personal_settings')}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box bgcolor="background.paper">
        <Box py={1} pl={!isRtl && 15} pr={isRtl && 15} position="relative" bgcolor="background.default">
          <Box sx={sx.head({ isRtl })}>
            <AccountIcon />
          </Box>
          <Stack direction="row" gap={1} alignItems="center">
            {verification_status && (
              <Box display="flex" alignItems="center" mr={1}>
                {verification_status}
              </Box>
            )}
            <Typography fontSize={14} fontWeight={600} color="primary.main">
              {currentUser.data?.username}
            </Typography>
          </Stack>
        </Box>
        <Box sx={sx.userInfo({ isRtl })}>
          <Typography fontSize={18} fontWeight={600} noWrap>
            {currentUser.data?.firstName} <br />
            {currentUser.data?.lastName}
          </Typography>
        </Box>
      </Box>
      <DialogContent>
        {open && (
          <Form onSubmit={handleSubmit(onUpdateUserData)}>
            <Stack gap={2}>
              <FormInput control={control} name="firstName" placeholder={translate('name')} />
              <FormInput control={control} name="lastName" placeholder={translate('surname')} />
              <FormInput
                control={control}
                name="email"
                placeholder={translate('email_address')}
                endAdornment={
                  <IconButton
                    size="medium"
                    tabIndex={-1}
                    disabled={currentUser.data?.emailVerified}
                    onClick={handleVerify}
                    sx={({ palette }) => ({
                      '& svg': { fill: currentUser.data?.emailVerified ? palette.success.main : palette.warning.main },
                    })}
                  >
                    {currentUser.data?.emailVerified ? <TaskAltIcon /> : <MarkEmailReadIcon />}
                  </IconButton>
                }
              />
              <FormPhoneInput control={control} name="phoneNumber" disabled={!!currentUser?.data?.phoneNumber} />
              <FormDatePicker control={control} name="birthDate" disabled={!!currentUser?.data?.birthDate} />
              <FormSelect
                control={control}
                name="country"
                placeholder={translate('country')}
                options={countries?.data?.map((item) => ({
                  id: item.id,
                  name: item.name,
                  icon: item.flag,
                }))}
              />
              <FormInput control={control} name="city" placeholder={translate('city')} />
              <FormInput control={control} name="addressOne" placeholder={translate('address')} />
              <Button variant="contained" type="submit">
                {translate('save_changes')}
              </Button>
            </Stack>
          </Form>
        )}
        <VerifyEmail open={emailOpen} onClose={handleEmailClose} />
      </DialogContent>
    </Dialog>
  );
};

export default AccountSettingsPopup;
